/*
  续租押金结算 - 列表页

  和换铺功能共用详情页组件。
*/
<template>
  <div>
    <!-- filters -->
    <a-card>
      <a-row :gutter="0" style="line-height: 3">
        <a-form layout="inline">
          <a-row :gutter="0" style="line-height: 3.5">
            <!-- 门店名称 -->
            <a-col :md="7" :sm="24">
              <a-form-item label="门店名称">
                <a-input
                  v-model="queryParam.originalFacilityName"
                  placeholder="请输入"
                  @pressEnter="refetch()"
                />
              </a-form-item>
            </a-col>
            <!-- 门店编号 -->
            <a-col :md="7" :sm="24">
              <a-form-item label="门店编号">
                <a-input
                  v-model="queryParam.originalFacilityCode"
                  placeholder="请输入"
                  @pressEnter="refetch()"
                />
              </a-form-item>
            </a-col>
            <!-- 店铺名称 -->
            <a-col :md="7" :sm="24">
              <a-form-item label="店铺名称">
                <a-input
                  v-model="queryParam.originalStoreName"
                  placeholder="请输入"
                  @pressEnter="refetch()"
                />
              </a-form-item>
            </a-col>
            <!-- 发送状态 -->
            <a-col :md="7" :sm="24">
              <a-form-item label="发送状态">
                <a-select
                  style="width: 180px"
                  v-model="queryParam.status"
                  allowClear
                  placeholder="请选择"
                  @change="refetch()"
                >
                  <a-select-option v-for="item in billStatus" :key="item.id">{{
                    item.name
                  }}</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <!-- 按钮 -->
            <a-col :md="7" :sm="16">
              <div>
                <a-button
                  style="margin-left: 8px"
                  @click="() => ((queryParam = {}), this.refetch())"
                  >重置</a-button
                >
                <!-- <a-popconfirm
                  v-if="hasEditPermission"
                  placement="bottomRight"
                  title="确认发送这些数据么？"
                  @confirm="confirmSendBill"
                  okText="Yes"
                  cancelText="No"
                >
                  <a-button
                    style="margin-left: 8px; width: 80px"
                    :disabled="selectedRowKeys.length <= 0"
                    type="primary"
                    >发送</a-button
                  >
                </a-popconfirm> -->
              </div>
            </a-col>
          </a-row>
        </a-form>
      </a-row>
    </a-card>
    <div>
      <a-spin :spinning="dataLoading || dataSending">
        <a-card style="margin-top: 24px">
          <a-table
            rowKey="id"
            :columns="tableColumns"
            :dataSource="continueRentList"
            :pagination="pagination"
            :scroll="{ x: 1320 }"
            @change="changePageSize"
          >
            <!-- :rowSelection="{
              selectedRowKeys: selectedRowKeys,
              onChange: onSelectChange,
            }" -->
            <span slot="contract" slot-scope="text, record" class="blueColor">
              <a @click="contractDetail(record.originalContractId)">{{
                record.originalContractCode
              }}</a>
            </span>
            <span slot="action" slot-scope="text, record">
              <div v-if="record.status === 1 && hasEditPermission" class="blueColor">
                <a @click="handleDetailsEdit(record)">调整</a>&nbsp;
                <a-popconfirm
                  placement="bottomRight"
                  title="确认发送吗？"
                  @confirm="confirmSendBill(record)"
                  okText="Yes"
                  cancelText="No"
                >
                  <a>发送</a>
                </a-popconfirm>
              </div>
              <div v-if="record.status === 2" class="blueColor">
                <a @click="handleDetailsView(record)">查看</a>&nbsp;
                <a-popconfirm
                  v-if="hasEditPermission"
                  placement="bottomRight"
                  title="确认撤回吗？"
                  @confirm="handleRecall(record)"
                  okText="Yes"
                  cancelText="No"
                >
                  <a>撤回</a>
                </a-popconfirm>
              </div>
            </span>
          </a-table>
        </a-card>
      </a-spin>
    </div>
  </div>
</template>
<script>
import moment from "moment";
  
import { mapGetters } from "vuex";
import { hasPermissions } from "../../utils/auth";
import { billStatus } from "@/objects/businessObjects";
import { FacilityActions } from "../../utils/actions";
import { defaultPagination } from "@/utils/pagination";

export default {
  name: "continueRentList",
  data() {
    return {
      // 账单状态
      billStatus,
      // 搜索
      queryParam: {},
      // 加载状态
      dataLoading: false,
      // 表格的列
      tableColumns: [
        {
          title: "门店名称",
          width: 200,
          dataIndex: "originalFacilityName",
          key: "originalFacilityName",
        },
        {
          title: "门店编号",
          width: 120,
          dataIndex: "originalFacilityCode",
          key: "originalFacilityCode",
        },
        {
          title: "档口编号",
          width: 100,
          dataIndex: "originalKitchenCode",
          key: "originalKitchenCode",
        },
        {
          title: "店铺名称",
          width: 200,
          dataIndex: "originalStoreName",
          key: "originalStoreName",
        },
        {
          title: "生成时间",
          width: 200,
          dataIndex: "gmtCreated",
          key: "gmtCreated",
          customRender: (text, row, index) => {
            if (row.gmtCreated) {
              return moment(row.gmtCreated).format("YYYY-MM-DD HH:mm:ss");
            } else {
              return "";
            }
          },
        },
        {
          title: "合同编号",
          width: 160,
          dataIndex: "originalContractCode",
          scopedSlots: { customRender: "contract" },
        },
        {
          title: "发送状态",
          width: 100,
          dataIndex: "status",
          key: "status",
          customRender: (text) => {
            const status = billStatus.find((s) => s.id === text);
            return status && status.name;
          },
        },
        {
          title: "平移押金",
          width: 120,
          dataIndex: "totalAmount",
          key: "totalAmount",
        },
        {
          title: "操作",
          width: 120,
          fixed: "right",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      continueRentList: [],
      // 分页信息
      pagination: defaultPagination(() => {}),
      // 表格中选中的行的key
      selectedRowKeys: [],
      // 发送中
      dataSending: false,
    };
  },
  methods: {
    refetch(pageSize, current) {
      axios({
        url:   this.$baseUrl + "accountDeposit/queryReletDepositList",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam,
        },
      }).then((res) => {
        if (res.data.success) {
          this.continueRentList = res.data.rows;
          this.pagination.total = res.data.count;
          this.selectedRowKeys = [];
        } else {
          this.$message.error(`${res.data.returnMsg}`);
        }
      });
    },
    // 表格CheckBox选择变化
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 表格变化（例如翻页）
    changePageSize(val) {
      this.current = val.current;
      this.pageSize = val.pageSize;
      this.refetch(this.pageSize, this.current);
    },
    // 发送账单
    confirmSendBill(record) {
      this.dataSending = true;
      axios({
        url:   this.$baseUrl + "accountDeposit/send",
        method: "GET",
        params: {
          accountDepositId: record.id,
        },
      })
        .then((res) => {
          if (res.data.success) {
            if (res.data.rows) {
              this.$message.info(res.data.returnMsg);
              this.refetch();
            }
          } else {
            this.$message.error(res.data.returnMsg);
          }
          this.dataSending = false;
        })
        .catch((error) => {
          this.dataSending = false;
          this.$message.error(error.message);
        });
    },
    // 查看
    handleDetailsView(record) {
      this.$router.push({
        path: "/finance/continueRent/viewDetail",
        query: {
          id: record.id,
          facilityCode: record.originalFacilityCode,
          facilityName: record.originalFacilityName,
          kitchenCode: record.originalKitchenCode,
          storeName: record.originalStoreName,
          tenantName: record.originalTenantName,
          contractCode: record.originalContractCode,
          contractId: record.originalContractId,
        },
      });
    },
    // 调整
    handleDetailsEdit(record) {
      this.$router.push({
        path: "/finance/continueRent/editDetail",
        query: {
          id: record.id,
          facilityCode: record.originalFacilityCode,
          facilityName: record.originalFacilityName,
          kitchenCode: record.originalKitchenCode,
          storeName: record.originalStoreName,
          tenantName: record.originalTenantName,
          contractCode: record.originalContractCode,
          contractId: record.originalContractId,
        },
      });
    },
    // 撤回
    handleRecall(record) {
      axios({
        url:   this.$baseUrl + "accountDeposit/cancel",
        method: "GET",
        params: {
          accountDepositId: record.id,
        },
      }).then((res) => {
        if (res.data.success) {
          this.$message.success(res.data.returnMsg);
          this.refetch();
        } else {
          this.$message.error(res.data.returnMsg);
        }
      });
    },
    // 合同跳转
    contractDetail(val) {
      this.$router.push({
        path: "../role/contractList/contractDetail",
        query: { contractDetailsId: val },
      });
    },
  },
  created() {
    this.refetch();
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    hasEditPermission() {
      return hasPermissions(
        [FacilityActions.CONTINUE_RENT_EDIT],
        this.authenticatedUser.actions
      );
    },
  },
};
</script>
<style scoped>
.button-panel {
  display: flex;
  flex-direction: row-reverse;
  margin: 12px;
}
</style>