/*
    业务对象
*/

//档口类型
export const stallsType = [
         {
           id: 1,
           name: "纯外卖",
         },
         {
           id: 2,
           name: "堂食",
         },
         {
           id: 3,
           name: "功能间",
         },
         {
           id: 4,
           name: "储藏间",
         },
         {
           id: 5,
           name: "洗碗间",
         },
         // 6 is the last one for better display.
         {
           id: 7,
           name: "花车",
         },
         {
           id: 8,
           name: "独立铺",
         },
         {
           id: 9,
           name: "虚拟档口",
         },
         {
           id: 6,
           name: "其他",
         },
       ];

// 商户入驻进度
export const tenantEnteringStatus = [
    { id: 0, name: '尚未启动' },
    { id: 1, name: '正在进行' },
    { id: 2, name: '已完成' },
]

//合同状态
export const contractStatus = [
    {
        id: 1,
        name: "预定中",
    },
    {
        id: 2,
        name: "已预订",
    },
    {
        id: 3,
        name: "预定作废",
    },
    {
        id: 4,
        name: "已签约回款",
    },
    {
        id: 5,
        name: "已退租",
    },
    {
        id: 6,
        name: "已驳回",
    },
    {
        id: 7,
        name: "已续租",
    },
    {
        id: 8,
        name: "已签约未回款",
    },
    {
        id: 9,
        name: "待修改",
    },
    {
        id: 10,
        name: "待签约",
    },
    {
        id: 11,
        name: "未签约已回款",
    },
];

// 账单状态
export const billStatus = [
    { id: 1, name: "未发送" },
    { id: 2, name: "已发送" }
]
